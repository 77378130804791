@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar{
    display: none;
}
.card:hover{
    content: 'İncelemek İçin Tıkalyın' !important;
}

#root{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
}

.default {
    background-image: url(https://placehold.co/600x400);
    background-repeat: no-repeat;
    background-position: center;
    background-clip: border-box;
    background-size: cover;
}
.bm-overlay{
    background-color: blue !important;
}
.btn{
    min-width: 4rem;
    aspect-ratio: 2/1;
    
    border-radius: 5px;
}
.p-fileupload-content:last-child {
   overflow-y: scroll !important;
   max-height: 20rem !important; 
}
.p-fileupload-buttonbar{
    min-width:  90% !important;
}
.p-accordion-content{
    padding: 10px !important;
}
.ql-editor{
    min-height: 200px !important;
}

.list::-webkit-scrollbar{
    display: block !important;
    width: 5px;
}
.list::-webkit-scrollbar-track{
    
    background-color: #ddd;
    
}
.list::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 20px;
    padding: 1rem !important;
}